import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    connection_snack: false,
    footer_props: {
      showFirstLastPage: true,
      "items-per-page-text": "لكل صفحة",
      "items-per-page-options": [10, 15, 30],
    },
    def_path: "https://edarra.com/tas2",
    requests: 0,
    user_romany_data:
      JSON.parse(localStorage.getItem("user_romany_data")) || null,
    token: localStorage.getItem("token_insurance") || null,
    menu: true,
    transfers:0,
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    user_data(state, user) {
      state.user_data = user;
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
      // console.log(token);
    },
    logout(state) {
      state.token = null;
      localStorage.removeItem("user_data");
      localStorage.removeItem("token_insurance");
    },
    public__request(state, item) {
      state.pages[item.name] = item.data;
    },
    user_romany_data(state, user) {
      state.user_romany_data = user;
    },

    // download_file(state, fileName) {
    //   console.log(fileName);
    //   axios({
    //     url: fileName, // File URL Goes Here
    //     method: "GET",
    //     responseType: "blob",
    //   })
    //     .then((res) => {
    //       // var FILE = window.URL.createObjectURL(new Blob([res.data]));

    //       // var docUrl = document.createElement("x");
    //       // docUrl.href = FILE;
    //       // docUrl.setAttribute("download", "file.pdf");
    //       // document.body.appendChild(docUrl);
    //       // docUrl.click();
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        formData.append("password", cred.password);
        var config = {
          method: "post",
          url: "adminLogin",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            const user = res.data.data;
            localStorage.setItem("token_insurance", token);
            localStorage.setItem("user_romany_data", JSON.stringify(user));
            context.commit("user_romany_data", user);
            context.commit("login", token);
            resolve(res);
            // console.log(res);
          })
          .catch((err) => {
            reject(err);
            // console.log(err.response);
          });
      });
    },

    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };

        axios(config)
          .then((res) => {
            resolve(res);
            context.state.connection_snack = false;
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            reject(err);
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
  },
  modules: {},
});
