import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import store from "../store/index";


const login = () => import("@/views/login.vue");
const Dashboard = () => import("@/views/admin/dashboard.vue");
const Customers = () => import("@/views/admin/customers.vue");
const certificates = () => import("@/views/admin/certificates.vue");
const Certificates_settings = () => import("@/views/admin/certificates_settings.vue");
const requests = () => import("@/views/admin/requests.vue");
const customer_profile = () => import("@/views/admin/customer_profile.vue");
const settings = () => import("@/views/admin/settings.vue");
const Transfares = () => import("@/views/admin/Transfares.vue");
const PageNotFound = () => import("@/views/PageNotFound.vue");

const routes = [
  {
    path: "/Customers",
    name: "Customers",
    component: Customers,
    meta: {
      title: "Customers",
      requiresAuth: true,
    },
  },
  {
    path: "/customer_profile",
    name: "customer_profile",
    component: customer_profile,
    meta: {
      title: "customer_profile",
      requiresAuth: true,
    },
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/certificates",
    name: "certificates",
    component: certificates,
    meta: {
      title: "certificates",
      requiresAuth: true,
    },
  },

  {
    path: "/Certificates_settings",
    name: "Certificates_settings",
    component: Certificates_settings,
    meta: {
      title: "Certificates_settings",
      requiresAuth: true,
    },
  },
  {
    path: "/Transfares",
    name: "Transfares",
    component: Transfares,
    meta: {
      title: "Transfares",
      requiresAuth: true,
    },
  },
  {
    path: "/requests",
    name: "requests",
    component: requests,
    meta: {
      title: "requests",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
    },
  },

  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },
  { path: "*", component: PageNotFound },
  ,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
export default router;
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next();
    } else {
      next("/Dashboard");
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  }
});
